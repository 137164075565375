@use '../../asstes/css/variables.scss' as helpers;

footer {
    .logo-gooter{
        width: 10vw;
    }
    box-sizing: border-box;

    .footer-contact-info{
        a{
            font-weight: 700;
            text-decoration: none;
            letter-spacing: 1px;
            margin: 3px;
            transition: all 0.4s;
            position: relative;
            display: block;
            font-size: 15px;
            color: #37343A;
            transition: all 500ms ease;
        }
    }

    .main-footer-container {
        display: flex;
        background-color: helpers.$yellowColor;
        padding-top: 20px;
        padding: 20px  helpers.$globalPadding;
        .row{
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        .col {
            flex: 0 0 33.333333%;
            max-width: 32.333333%;
        }

        .col-middle {
            width: 20%;
        }

        .footer-contact {
            &-mail {
                font-size: 15px;
                line-height: 3em;
                color: helpers.$white-color;
                position: relative;
                display: inline-block;

                &::before {
                    position: absolute;
                    bottom: 9px;
                    left: 0;
                    right: 0;
                    height: 1px;
                    background-color: helpers.$mainColor;
                    content: "";

                }
            }

            &-phone {
                font-family: 'Jost', sans-serif;
                font-size: 24px;
                color: helpers.$white-color;
                font-weight: 400;
                display: block;
                -webkit-transition: all 500ms ease;
                transition: all 500ms ease;
            }

            &-text {
                position: relative;
                display: block;
                font-size: 15px;
                color: #37343A;
                transition: all 500ms ease;
                font-weight: 700;
                text-transform: uppercase;
                text-decoration: none;
                letter-spacing: 1px;
                color: #37343A;
                margin: 3px;
                transition: all 0.4s;
            }

            &-social-list {
                a {
                    .svg-inline--fa {
                        font-size: 25px;
                        color: helpers.$blackColor;
                        transition: all 500ms ease;

                        &:hover {
                            color: helpers.$pinkColor;
                        }
                    }
                }

                a {
                    + {
                        a {
                            margin-left: 15px;
                        }
                    }
                }

            }



        }

        .footer-explore {
            &-list {
                padding: 0;

                li {
                    a {
                        position: relative;
                        display: block;
                        font-size: 15px;
                        color: helpers.$blackColor;
                        -webkit-transition: all 500ms ease;
                        transition: all 500ms ease;

                        &:hover {
                            padding-left: 15px;
                        }

                        &::before {

                            position: absolute;
                            top: 55%;
                            left: 0;
                            bottom: 0;
                            width: 6px;
                            height: 6px;
                            content: "";
                            background-color: helpers.$pinkColor;
                            -webkit-transform: translateY(-50%);
                            transform: translateY(-50%);
                            opacity: 0;
                            border-radius: 50%;
                            transition: all 500ms ease;
                            transition: all 500ms ease;

                        }
                    }

                    a:hover:before {
                        opacity: 1;
                    }
                }

            }
        }


        .footer-title {
            font-size: 24px;
            color: helpers.$blackColor;
            margin-bottom: 20px;
        }


        .bottom-line-footer {
            background-color: helpers.$secondaryColor2;
            height: 50px;
            padding:20px  helpers.$globalPadding;

        }

    }

    .bottom-line-footer {
        background-color: helpers.$yellowColor;
        padding:20px  helpers.$globalPadding;
        display: flex;
        justify-content: center;
        width: 100%;

        p {
            a {
                color: helpers.$white-color;

            }
        }

    }
}

@media screen and (max-width: 1000px) {
    footer {
        .logo-gooter{
            width: 30vw;
        }
        .main-footer-container {
            .row {
                flex-direction: column;

                .col {
                    min-width: 100%;
                    flex: unset;
                    .footer-about{
                        padding-bottom: 20px;
                    }
                    .footer-contact-social-list {
                        a {
                            .svg-inline--fa {
                                font-size: 25px;
                                transition: all 500ms ease;
        
                                &:hover {
                                    color: helpers.$mainColor;
                                }
                            }
                        }
        
                        a {
                            + {
                                a {
                                    margin-left: 15px;
                                }
                            }
                        }
        
                    }
                    
                }

                .col-middle {
                   width: 100%;
                }
            }
        }
    }
}