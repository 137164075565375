@use '../../asstes/css/variables.scss' as helpers;

.services-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    div {
        color: helpers.$blackColor;
    }

    .creation__title {

        h1,
        h3, p {
            color: helpers.$whiteColor;
        }

    }

    >div {
        max-width: 1280px;

        &.services-page__strategy {
            max-width: unset;
            width: 100%;
            background-color: helpers.$yellowColor;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            >div {
                width: 100%;
                max-width: 1280px;

            }
        }

        &.services-page__content_creation {
            max-width: unset;
            width: 100%;
            background-color: helpers.$pinkColor;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            p {
                color: helpers.$whiteColor;
            }
            >div {
                width: 100%;
                max-width: 1280px;

            }
        }
    }

    .strategy,
    .creation {

        &__title {
            display: flex;
            align-items: baseline;

            img {
                width: 50px;
                height: 50px;
            }

            h1 {
                font-size: 54px;
                margin-right: 20px;
            }

            h3 {
                font-size: 30px;
            }
        }

        &__step {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin: 20px 0px;

            &__row {
                display: flex;
                align-items: self-start;
                gap: 10px;

            }

            .row {
                &__box {
                    background-color: antiquewhite;
                    padding: 10px;
                    font-size: 27px;
                    font-weight: 500;
                    flex: 1;
                    border-radius: 50px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    gap: 10px;

                    span {
                        font-size: 16px;
                    }
                }

                &__img {
                    align-self: center;
                }
            }

            .carContainer {
                display: flex;
                flex-direction: column;

                img {
                    object-fit: contain;
                    width: 3.7vw;
                }
            }

            .celi_na_branda {
                display: flex;
                align-items: center;
                flex: 1;
                gap: 10px;

                &--down {
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    flex-direction: column;

                    img {
                        transform: rotate(90deg);
                    }
                }

                &--left {
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    flex-direction: row-reverse;

                    img {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
    .content_creation{
        &__innerContainer{
            display: flex;
            align-items: center;
            &_luch{
                width: 100%;
                height: 300px;
                clip-path: polygon(0 0, 100% 16%, 100% 81%, 0% 100%);
                background-color: white;
            }
            &_wrapper{
                width: 100%;
                position: relative;
            }
        }
    }
}