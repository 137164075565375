@use '../../asstes/css/variables.scss' as helpers;
@import '../../asstes/css/mixins';

.contact-form {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
   
    
    .success-message{
        display: flex;
        align-self: center;
        text-align: center;
        background: helpers.$yellowColor;
        padding: 30px;
        margin: 30px 0;
        font-size: 24px;
        color: helpers.$blackColor;
        font-weight: 500;
    }
}
.form-button{
    width: 100%;
    display: flex;
    justify-content: center;
    button{
        background: helpers.$pinkColor;
        color: helpers.$whiteColor;
        border: none;
        border-radius: 18px;
        padding: 10px 30px;
        font-size: 30px;
        cursor: pointer;
        &:hover{
            background:#f94480b5 ;
        }
    }
}
.form {
    

    font-size: helpers.$font-size-xxl ;
    color: helpers.$secondaryColor;

    .row {
        display: flex;
        flex-wrap: wrap;

        .form-group {
            margin-bottom: 1rem;
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 15px;

         
        }
        .from-bottom-section {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }

    }

}
input[type="password"],
input[type="email"],
input[type="text"],
input[type="file"],
input[type="url"],
textarea {
    max-width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    height: auto;
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-style: solid;
    display: block;
    width: 100%;
    line-height: 1.5em;
    font-family: 'Jost', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: helpers.$blackColor;
    background-image: none;
    border: 1px solid helpers.$blackColor;
    border-color: ease-in-out .15s, box-shadow ease-in-out .15s;
    border-radius: 30px;

    &::selection {
        color: #fff;
        background: helpers.$pinkColor;
    }

}

input:focus,
textarea:focus-visible {
    border-width: 2px;
    border-color: helpers.$pinkColor;
    outline: none;
}

input:focus,
textarea:focus {
    border-width: 2px;
    border-color: helpers.$pinkColor;
}

@media (max-width: 1000px) {
.form .row .form-group {
    max-width: 100%;
    padding: 0;
}
}
@media (max-width: 750px) {
    .form-button{
        width: 100%;
        display: flex;
        justify-content: center;
        button{
           
            font-size: 24px;
            cursor: pointer;

            &:active{
                background:#f94480b5 ;
            }
        }
    }
}