@use '../../asstes/css/variables.scss' as helpers;

@font-face {
  font-family: 'Kraimorie';
  src: url('../../asstes/fonts/92zPtBhPNqw79Ij1E865zBUv7myRJTVFNIg8mg.woff2');
}


header {
  width: 100%;
  transition: background-color 0.3s;
  z-index: 105;

  .container.mainClass {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 114px;
    width: 100%;
    gap: 10px;

    .logo {
      position: relative;
      width: 10px;
      height: 100%;
    }

    .rightSide {
      border-style: solid;
      width: 100%;
      border-width: 0 0 1px 0;
      border-color: #F9447F;
      height: 100%;
      display: flex;
      padding-top: 2%;
      justify-content: end;
      padding-right: 8%;
    }

    .leftSide {
      border-style: solid;
      width: 20%;
      border-width: 0 0 1px 0;
      border-color: #DDE468;
      height: 100%;
    }

  }
}

header>* {
  font-family: 'Kraimorie';
}

header.sticky {
  position: fixed;
  background-color: white;
  z-index: 104;
}

.container img {
  width: 164px;
  position: absolute;
  top: 41px;
  left: -93px;
}

.burger {
  color: helpers.$pinkColor;
}

@media (max-width: 1000px) {
  header {
    .container {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
    }

    .burger {
      width: 35px;
      height: 35px;
    }
  }
  header .container.mainClass .rightSide {
    align-items: center;
  }
  .container img {
    top:unset;
    left: unset;
    position: relative;
    width: 15vw;
  }
  header .container.mainClass .logo {
    width: auto;
    border-width: 0 0 1px 0;
    border-color: #F9447F;
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2%;
    justify-content: end;
    padding-left: 8%;
  }
  header .container.mainClass .leftSide {
    display: none;
  }
  header .container.mainClass {
    gap: 0;
  }
  .homepage-container {
    flex-direction: column-reverse;
    .homepage-container img {
      width: 100%;
  }
  }
}
@media (max-width: 720px) {
  .container img {
    top:unset;
    left: unset;
    position: relative;
    width: 20vw;
  }
}
