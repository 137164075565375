@use '../../asstes/css/variables.scss' as helpers;

.home-page-contant-form {
  width: 100%;
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1280px;


  >.h1 {
    font-size: 54px;
    background-color: helpers.$pinkColor ;
    color: helpers.$whiteColor;
    padding: 20px 50px;
    margin-bottom: 30px;
    text-align: center;
    font-weight: bold;
  }

  p {
    text-align: center;
  }
}


.home-page-section {
  background-color: helpers.$whiteColor;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right-side-pointers {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 5vw;
  margin-bottom: 5vw;
}

.left-side-pointers {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 5vw;
}

.homepage-container {
  display: flex;
  max-width: 1280px;
  width: 100%;
  margin-top: 40px;
  justify-content: center;
  align-items: flex-start;

  .what-we-do {
    flex-direction: column;
    display: flex;

    p {
      display: flex;
      gap: 10px;

      img {
        width: 23px;
        object-fit: contain;
      }
    }



    .butn-dark {
      margin-top: 78px;
    }
  }

  .mainTitle {
    font-size: 54px;
    line-height: 54px;
    font-weight: 900;
    color: helpers.$blackColor;

  }

  >div {
    width: 100%;
    color: helpers.$blackColor;
    display: flex;
    flex-direction: column;

    >p {
      display: flex;
    }

    .heart {
      width: 60px;
      align-self: flex-end;
    }

    h4 {
      text-transform: uppercase;
      font-weight: 900;
      display: flex;
    }



    .tiktok {
      font-size: 60px;
      line-height: 60px;

    }


    >div {
      display: flex;

      h6 {
        font-size: 27px;
        margin-right: 10px;
      }
    }
  }

  video {
    width: 50%;
  }
}

.home-page-more-container {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 30px 0;
}

.home-page-more {
  font-size: 22px;

  >strong {
    margin-right: 10px;
  }
}

.why-tiktok {
  background-color: helpers.$pinkColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10% 80px;
  width: 100%;

  .h1 {
    font-size: 54px;
    color: helpers.$whiteColor;
    text-transform: uppercase;
    font-weight: 900;
    margin: 30px 0;

    strong {
      color: #DDE468;
      font-weight: 900;

    }
  }

  .why-tiktok-inner-container {
    display: flex;
    width: 100%;
    max-width: 1280px;
    justify-content: space-between;

    .why-tiktok-righ-side {
      width: 46%;
      display: flex;
      flex-direction: column;
      align-items: end;

      p {
        color: helpers.$whiteColor;
        text-align: end;
        font-size: 18px;
      }
    }

    video {
      width: 24%;
      height: fit-content;
    }

    h3 {
      font-size: 44px;
      color: helpers.$whiteColor;
      font-weight: 800;

      strong {
        color: #DDE468;
      }
    }
  }
}

.short-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: helpers.$whiteColor;
  max-width: 1280px;

  .h1 {
    color: #DDE468;
    font-size: 54px;
    font-weight: 900;
    margin-top: 30px
  }

  h3 {
    color: #37343A;
    font-size: 30px;
    margin-bottom: 30px;
    text-align: center;
  }

  .short-form-relative {
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;

    .short-form-img {
      width: 19%;
    }

    .short-form-video {
      width: 24%;

      video {
        width: 100%;
      }
    }

    .poiners {
      display: flex;
      align-items: center;



      .stick {
        width: 130px;
        height: 3px;
        background: helpers.$pinkColor;
      }

      .ring {
        width: 40px;
        height: 40px;
        background: helpers.$pinkColor;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: helpers.$whiteColor;
      }

      h5 {
        font-size: 20px;
        color: #37343A;
      }
    }
  }
}

.round-bottom {
  border-bottom-left-radius: 50% 150px !important;
  border-bottom-right-radius: 50% 150px !important;
  width: 100%;
  background-color: helpers.$whiteColor;
  height: 54px;
}

.container-shape {
  width: 100%;
  height: 100px;
  padding-bottom: 50px;
  overflow: hidden;
  background-color: helpers.$pinkColor;
}

.home-services {
  max-width: 1280px;
  padding: 80px 0%;

  &-title {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    img {
      width: 64px;
      height: 64px;
    }

    h1 {
      font-size: 50px;
      color: #37343A;
      font-weight: 900;
    }
  }

  &-main-container {
    display: flex;
    justify-content: center;
    width: 100%;

    >div {
      width: 30%;
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        text-align: center;
      }

      h6 {
        font-size: 24px;
        color: #37343A;
      }

      .spacer {
        height: 40px;
        width: 2px;
        background-color: helpers.$pinkColor;
        margin: 10px 0;
      }

      .spacer-yellow {
        height: 40px;
        width: 2px;
        background-color: #DDE468;
        margin: 10px 0;
      }

      .big-yellow {
        width: 10vw;
        height: 10vw;
        background-color: #f1f2c1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;

        .small-yellow {
          width: 75%;
          height: 75%;
          border-radius: 100%;
          background-color: #DDE468;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .big-pink {
        width: 10vw;
        height: 10vw;
        background-color: #fdb2ca;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;

        .small-pink {
          width: 75%;
          height: 75%;
          border-radius: 100%;
          background-color: helpers.$pinkColor;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .lamp {
        width: 50%;
      }
    }
  }
}

.why-social-media {
  max-width: 1280px;
  padding: 80px 0%;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  >h4 {
    font-size: 50px;
    color: #37343A;
    display: flex;
    align-items: center;
    justify-content: center;

    .h1 {
      font-size: 54px;
      font-weight: 900;
      color: helpers.$pinkColor;
      margin-left: 10px
    }
  }

  p {
    text-align: center;
  }

  img {
    width: 68%;
  }
}

.home-page-banner {
  background-color: #DDE468;
  padding: 10px 10%;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  >div {
    display: flex;
    flex-direction: column;
    align-items: center;

    .h1 {
      font-size: 50px;
      line-height: 50px;
      font-weight: bold;
      color: helpers.$whiteColor;
    }

    h3 {
      font-size: 30px;
      line-height: 30px;
      color: #37343A;
    }
  }
}

.home-page-brands {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 20px 0;

  .brands-title {
    display: flex;
    align-items: baseline;
    color: #37343A;
    margin: 40px 0;

    .h1 {
      font-size: 54px;
      font-weight: 900;
    }

    h3 {
      font-size: 50px;
      font-weight: 400;
    }
  }
}

// .home-page-results {
//   padding: 10px 0%;
//   width: 100%;
//   background-color: helpers.$pinkColor;
//   justify-content: center;
//   display: flex;
//   flex-direction: column;

//   .home-page-results-title {
//     display: flex;
//     align-items: baseline;
//     max-width: 1280px;
//     width: 100%;
//     align-self: center;
//     margin: 40px 0;


//     img {
//       width: 3vw;
//       height: 3vw;
//     }

//     h1 {
//       font-size: 54px;
//       font-weight: 800;
//       line-height: 54px;
//       color: helpers.$whiteColor;
//       margin-right: 20px;
//     }

//     h3 {
//       font-size: 57px;
//       line-height: 57px;
//       color: #DDE468;
//     }
//   }

//   .video-container {
//     display: flex;
//     justify-content: space-between;
//     max-width: 1280px;
//     align-self: center;
//     align-items: center;

//     >div {
//       width: 22%;

//       .result-min-container {
//         display: flex;
//         justify-content: space-around;
//         align-items: center;
//         margin-bottom: 10px;

//         >div {
//           display: flex;
//           align-items: center;
//         }

//         span {
//           color: helpers.$whiteColor;
//         }

//         img {
//           width: 18px;
//           object-fit: contain;
//           margin-right: 3px
//         }

//         .play {
//           width: 14px;
//         }
//       }

//       >video {
//         width: 100%;
//       }
//     }
//   }
// }

@media (max-width: 1350px) {
  .home-page-section {
    >div {
      &.container-shape {
        padding: 0;
      }

      &.why-tiktok {
        padding: 0 10% 80px;
      }

      padding: 0 10%
    }

  }

  .homepage-container {
    .mainTitle {
      font-size: 50px;
      line-height: 50px;
    }

    >div {
      .tiktok {
        font-size: 54px;
        line-height: 54px;
      }
    }
  }

  .why-tiktok {
    .why-tiktok-inner-container {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .why-tiktok-righ-side {
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: end;

        p {
          font-size: 16px;
        }

        h3 {
          font-size: 38px;
        }
      }
    }
  }

  .home-page-more {
    font-size: 18px;

    >strong {
      margin-right: 10px;
    }
  }

  .home-services-title img {
    width: 44px;
    height: 44px;
  }

  .home-services-main-container>div h6 {
    font-size: 22px;
  }

  .home-services-main-container>div p {
    text-align: center;
    font-size: 16px;
  }

  .why-social-media>h4 .h1 {
    font-size: 54px;
  }

  .why-social-media>h4 {
    font-size: 37px;
  }



  .home-page-results .home-page-results-title h3 {
    font-size: 37px;
  }
}

@media (max-width: 1555px) {
  .short-form-container .short-form-relative .poiners h5 {
    font-size: 14px;
  }
}

@media (max-width: 1000px) {
  .home-services {
    margin: 30px 0;

    &-title {
      justify-content: center;
      margin-bottom: 20px;

      h1 {
        font-size: 50px;
      }

      img {
        width: 50px;
        height: 50px;
      }
    }

  }

  .homepage-container {
    .ima-mqsto {
      flex-direction: row;

      h6 {
        font-size: 27px;
        margin-right: 10px
      }
    }

  }

  .homepage-container video {
    width: 100%;
  }

  .homepage-container .mainTitle {
    font-size: 45px;
    line-height: 50px;
  }

  .homepage-container>div .tiktok {
    font-size: 50px;
    line-height: 60px;
  }

  .homepage-container .what-we-do .butn-dark {
    align-self: center;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
  }

  .home-page-more-container {
    padding: 0 5%;
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .home-page-more {
    font-size: 14px;
    text-align: center;
  }

  .why-tiktok .h1 {
    font-size: 40px;
    color: helpers.$whiteColor;
    text-transform: uppercase;
    font-weight: 900;
  }

  .why-tiktok .why-tiktok-inner-container {
    flex-direction: column;
    align-items: center;
  }

  .why-tiktok .why-tiktok-inner-container video {
    width: 60%;
  }

  .why-tiktok .why-tiktok-inner-container .why-tiktok-righ-side {
    align-items: center;
  }

  .why-tiktok .why-tiktok-inner-container .why-tiktok-righ-side p {
    text-align: center;
  }

  .short-form-container .short-form-relative .poiners .stick {
    width: 2vw;

  }

  .short-form-container .h1 {
    font-size: 50px;
  }

  .home-services-main-container {
    flex-wrap: wrap;
    gap: 2vw;

    >div {
      width: 48%;
    }

    .reverse-col {
      flex-direction: column-reverse;
    }
  }

  .why-social-media>h4 {
    font-size: 50px;
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 50px;
    }
  }

}

@media (max-width: 480px) {

  .homepage-container .mainTitle {
    font-size: 30px;
    line-height: 30px;
  }

  .homepage-container>div .tiktok {
    font-size: 40px;
    line-height: 40px;
  }

  .homepage-container .ima-mqsto h6 {
    font-size: 20px;
    margin-right: 10px;
  }

  .why-tiktok .h1 {
    font-size: 40px;
    color: helpers.$whiteColor;
    text-transform: uppercase;
    font-weight: 900;
  }
}

@media (max-width: 430px) {
  .why-tiktok .h1 {
    font-size: 30px;
    color: helpers.$whiteColor;
    text-transform: uppercase;
    font-weight: 900;
  }

  .why-tiktok .why-tiktok-inner-container .why-tiktok-righ-side h3 {
    font-size: 30px;
  }

}

@media (max-width: 750px) {
  .short-form-container h3 {

    font-size: 16px;

  }

  .home-page-results .video-container>div .result-min-container img {
    width: 11px;
  }

  .home-page-results .video-container>div .result-min-container .play {
    width: 9px;
  }

  .home-page-results .home-page-results-title img {
    width: 7vw;
    height: 7vw;
  }

  .home-page-contant-form {
    >.h1 {
      font-size: 25px;
    }

    p {
      text-align: center;
    }
  }

  .home-page-banner>div {
    padding: 30px 0;
  }

  .why-tiktok .why-tiktok-inner-container .why-tiktok-righ-side {
    width: 100%;
  }

  .home-page-section {
    >.home-page-brands {
      padding: 0;
    }
  }

  // .home-page-results {
  //   .video-container {
  //     flex-wrap: wrap;
  //     gap: 4vw;

  //     >div {
  //       width: 38vw;

  //       .result-min-container {
  //         display: flex;
  //         justify-content: space-between;

  //         span {
  //           color: helpers.$whiteColor;
  //           font-size: 10px;
  //         }
  //       }

  //       >video {
  //         width: 100%;
  //       }
  //     }
  //   }

  //   .home-page-results-title {
  //     justify-content: center;
  //   }
  // }

  .short-form-container .short-form-relative .short-form-video {
    width: 50%;
  }

  .short-form-container .short-form-relative .poiners {
    .ring {

      background: helpers.$pinkColor;
      color: helpers.$whiteColor;
      width: 18px;
      height: 18px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 9px;
    }

    h5 {
      font-size: 10px;
      color: #37343A;
      max-width: 77%;

    }
  }

  .short-form-container .h1 {
    font-size: 25px;
    text-align: center;
  }

  .short-form-container {
    padding: 0 5%;
  }

  .left-side-pointers {
    gap: 5vw;

    h5 {
      font-size: 9px;
      text-align: end;
    }
  }

  .right-side-pointers {
    gap: 8vw;

    h5 {
      font-size: 9px;

    }
  }

  .home-services {
    padding: 25px 0%;

    &-title {
      justify-content: center;

      h1 {
        font-size: 30px;
      }

      img {
        width: 25px;
        height: 25px;
      }
    }

  }

  .home-services-main-container>div {
    .big-pink {
      width: 25vw;
      height: 25vw;
    }

    .big-yellow {
      width: 25vw;
      height: 25vw;
    }

    p {
      font-size: 16px;
      line-height: 16px;
    }

    h6 {
      font-size: 14px;
      text-align: center;
    }

    .spacer {
      width: 5px;
    }

    .spacer-yellow {
      width: 5px;
    }
  }

  .why-social-media>h4 {
    font-size: 25px;
    display: flex;
    flex-direction: column;

    .h1 {
      font-size: 25px;
      font-weight: bold;
      text-align: center;
    }
  }

  .why-social-media {
    padding: 80px 0;
    margin: 30px 0;

    p {
      font-size: 16px;
    }

    img {
      margin-top: 30px;
      width: 100%;
    }
  }

  .home-page-banner>div .h1 {
    font-size: 22px;
    line-height: 22px;
  }

  .home-page-banner>div h3 {
    font-size: 12px;
    line-height: 12px;
  }

  .home-page-brands .brands-title {
    .h1 {
      font-size: 25px;
    }

    h3 {
      font-size: 16px;
      text-align: center;
      font-weight: 400;
    }
  }

  .home-page-brands .brands-title {
    padding: 0 10%;
  }

  .home-page-results .home-page-results-title {
    h1 {
      font-size: 25px;
      margin-right: 5px
    }

    h3 {
      font-size: 25px;
    }
  }
}

@media (max-width: 1330px) {}