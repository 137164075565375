@use '../../asstes/css/variables.scss' as helpers;

@media (max-width: 1350px) {
  .tik-tok-results-section {
    >div {
      padding: 0 10%
    }

  }
}

.tik-tok-results-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  .single-brand {
    display: flex;
    max-width: 1280px;
    width: 100%;
    color: helpers.$blackColor;
    margin: 50px 0;

    &--reversed {
      flex-direction: row-reverse;

      .logoAndTitle {
        flex-direction: row-reverse;
      }

      .single_stat {
        h1 {
          font-size: 40px;
          line-height: 40px;
          font-weight: 700;
        }

        display: flex;
        gap: 20px;
        margin-right: 28%;

        &--heart {
          margin-right: 26%;
        }
      }
    }

    h1 {
      font-size: 54px;
      line-height: 54px;
      font-weight: 700;
    }

    .logoAndTitle {
      display: flex;
      align-items: center;
      flex: 1;

      h1 {

        font-weight: 900;
        width: 70%;
      }

      .brand-wrapper {
        width: 100px;
        height: 100px;
        border-radius: 100%;
        overflow: hidden;
        // padding: 7px;
        border: solid 1px #37343A;

        &--insa {
          padding: 7px;
        }

        img {
          width: 100%;
        }
      }
    }

  }
}

.titles-and-stats {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .statistics_wrapper {
    flex: 2
  }

  .single_stat {
    display: flex;
    gap: 20px;
    margin-left: 30%;

    h1 {
      font-size: 40px;
      line-height: 40px;
      font-weight: 700;

    }

    img {
      object-fit: contain;
      width: 40px;
    }

    &--heart {
      margin-left: 26%;

      img {
        object-fit: contain;
        width: 50px;
      }
    }



    >div {
      span {
        font-size: 25px;
        font-weight: 600;
      }
    }
  }
}

.video-of-clients {
  width: 70%;

  video {
    width: 100%;
  }
}

@media (max-width: 750px) {

  .tik-tok-results-section {

    .single-brand {
      h1 {
        font-size: 25px;
        line-height: 25px;
      }

      .logoAndTitle {}
    }
  }
}

@media (max-width: 1050px) {
  .tik-tok-results-section .single-brand {
    display: flex;
    flex-direction: column;
  }

  .video-of-clients {
    width: 100%;
  }

  .tik-tok-results-section {
    .titles-and-stats {
      width: 100%;

      .statistics_wrapper {
        flex-direction: row;
        display: flex;
        flex: none;
        justify-content: space-between;
      }

      .single_stat {
        display: flex;
        gap: 10px;
        margin-left: unset;
        margin-right: unset;
      }

      .tik-tok-results-section .single-brand--reversed .single_stat {
        display: flex;
        gap: 10px;
        margin-right: unset;
      }
    }

    .single-brand {
      .logoAndTitle h1 {
        width: unset;
      }

      .logoAndTitle {
        display: flex;
        align-items: center;
        flex: 1 1;
        gap: 10px;
      }
    }
  }
}

@media (max-width: 750px) {
  .tik-tok-results-section {
    .titles-and-stats {
      .statistics_wrapper {
        flex-direction: column;
        padding: 15px;

        .single_stat {
          >div {
            display: flex;
            gap: 10px;
          }
        }

      }
    }
  }

  .tik-tok-results-section .single-brand h1 {
    font-size: 22px;
    line-height: unset;
  }

  .tik-tok-results-section .single-brand--reversed .single_stat h1 {
    font-size: 22px;
    line-height: unset;
  }

  .tik-tok-results-section .single-brand .logoAndTitle {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex: 1 1;
    gap: 10px;
  }

  .titles-and-stats .single_stat--heart img {
    object-fit: contain;
    width: 40px;
  }

  .titles-and-stats .single_stat img {
    object-fit: contain;
    width: 30px;
  }

  .titles-and-stats .single_stat>div span {
    font-size: 20px;
    font-weight: 600;
  }

  .home-page-results .home-page-results-title img {
    width: 7vw;
    height: 7vw;
  }

  element.style {}

  .tik-tok-results-section {

    margin-top: unset;
  }
}