.keen-slider:not([data-keen-slider-disabled]){
  align-items: center;
}
.keen-slider__slide{
  img{
    object-fit: contain;
    width: 5vw;
    filter: grayscale(1);
    &:hover{
      filter: none;
    }
  }

}
@media (max-width: 1000px) {
  .keen-slider__slide{
    img{
      object-fit: contain;
      width: 15vw;
      filter: grayscale(1);
      &:hover{
        filter: none;
      }
    }
  
  }
}