
:root{
  --large-fontsiza:55px;
  --medium-fontsiza:45px;
  --small-fontsiza:16px
}
/* html {
  scroll-behavior: smooth;
  margin: 0px !important;
  padding: 0px !important;
  width: 100%;
  height: 100%;
} */
* {
  box-sizing: border-box;
}

    
body {
    font-family: "Inter", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width:100vw; 
    overflow-x:hidden;
    

  }
  


::selection {
    color: #fff;
    background: #F9447F;
}

section{
  background-color :#f7f7f7
}
@media (max-width: 1000px) {

.back-door{
  display: none;
  }
}