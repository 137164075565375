@use '../../asstes/css/variables.scss' as helpers;



@font-face {
    font-family: SocialMediaFont;
    src: url('./asstes/fonts/Jost-VariableFont_wght.ttf');
}

* {
    margin: 0;
    -webkit-user-drag: none;
    font-family: SocialMediaFont;
}

body {
    margin: 0 !important;
    background: #FFFBFB;
}

p {
    font-family: SocialMediaFont;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.75em;
    color: #37343A;
}

a {
    text-decoration: none;
}

