$mainColor: #80CC82;
$secondaryColor: #222;
$secondaryColor2: #1e1e1e;
$thirdColor: #666;
$color_4: green;
$font-family_1: SocialMediaFont;
$background-color_1: white;


$blackColor:#37343A;
$pinkColor:#F9447F;
$yellowColor:#DDE468;
$whiteColor:#FFFBFB;

@font-face {
	font-family: 'SocialMediaFont';
	src: url('../fonts/Jost-VariableFont_wght.ttf');
}


// 
$YsabeauInfant: 'YsabeauInfant';
$Amastetic: 'Amastetic';
$BackwardsSans: 'BackwardsSans';
// Font sizes


$oracle-color: #81523F;
$plantation: #81523F;
$white-color: #FFFFFF;
$lavender-blush-color: #FFF5F9;
$lavender-blush-color-transperent: rgb(255 255 255 / 62%);
$kobi-color: #EA9CB7;

;
//colors // dont touch them, later in the project weill be merged into one;
$color-dark-green: #81523F;
$color-background-green: #38736F;
$color-light-green: #C3D5D3;
$color-gray: #999999;
$color-light-pink: #FFF5F9;
$color-dark-pink: #076865;
$color-light-blue: #D7E3E2;

// font-sizes
$footer-padding: 39px 7.3%;

$font-size-xs: 0.75rem;
/* 12px */
$font-size-sm: 0.875rem;
/* 14px */
$font-size-md: 1rem;
/* 16px */
$font-size-lg: 1.125rem;
/* 18px */
$font-size-xl: 1.25rem;
/* 20px */
$font-size-xxl: 1.5rem;
/* 24px */
$font-size-2xl: 2rem;
/* 32px */
$font-size-3xl: 3rem;
/* 48px */
$font-size-4xl: 4.5rem;
/* 72px */

// padding
$globalPadding: 5%;

  