@use '../../asstes/css/variables.scss' as helpers;
.wrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 10;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .menu {
        position: inherit;
        width: 100/3*2%;
        height: 100%;
        background: helpers.$white-color;
        z-index: 10;
        box-shadow: 1px 1px 2px 0px #0000003b;

        >div:first-child {
            height: 100px;
            display: flex;
            justify-content: flex-end;

            svg {
                margin: 30px 20px 0 45px;
                width: 45px;
                height: 45px;
                color: helpers.$pinkColor;
            }
        }
    }

    .backdrop {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0px;
        left: 0px;
        backdrop-filter: blur(10px);
    }
}