
@use '../../asstes/css/variables.scss' as helpers;


.home-services {
	max-width: 1280px;
	padding: 80px 0%;
  
	&-title {
	  display: flex;
	  align-items: center;
	  margin-bottom: 30px;
  
	  img {
		width: 64px;
		height: 64px;
	  }
  
	  h1 {
		font-size: 50px;
		color: #37343A;
		font-weight: 900;
	  }
	}
  
	&-main-container {
	  display: flex;
	  justify-content: center;
	  width: 100%;
  
	  >div {
		width: 30%;
		display: flex;
		flex-direction: column;
		align-items: center;
  
		p {
		  text-align: center;
		}
  
		h6 {
		  font-size: 24px;
		  color: #37343A;
		}
  
		.spacer {
		  height: 40px;
		  width: 2px;
		  background-color: helpers.$pinkColor;
		  margin: 10px 0;
		}
  
		.spacer-yellow {
		  height: 40px;
		  width: 2px;
		  background-color: #DDE468;
		  margin: 10px 0;
		}
  
		.big-yellow {
		  width: 10vw;
		  height: 10vw;
		  background-color: #f1f2c1;
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  border-radius: 100%;
  
		  .small-yellow {
			width: 75%;
			height: 75%;
			border-radius: 100%;
			background-color: #DDE468;
			display: flex;
			justify-content: center;
			align-items: center;
		  }
		}
  
		.big-pink {
		  width: 10vw;
		  height: 10vw;
		  background-color: #fdb2ca;
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  border-radius: 100%;
  
		  .small-pink {
			width: 75%;
			height: 75%;
			border-radius: 100%;
			background-color: helpers.$pinkColor;
			display: flex;
			justify-content: center;
			align-items: center;
		  }
		}
  
		.lamp {
		  width: 50%;
		}
	  }
	}
  }
  @media (max-width: 1350px) {
	.home-services-main-container>div h6 {
		font-size: 22px;
	  }
	
	  .home-services-main-container>div p {
		text-align: center;
		font-size: 16px;
	  }
	}
	@media (max-width: 1000px) {
		.home-services {
		  margin: 30px 0;
	  
		  &-title {
			justify-content: center;
			margin-bottom: 20px;
	  
			h1 {
			  font-size: 50px;
			}
	  
			img {
			  width: 50px;
			  height: 50px;
			}
		  }
	  
		}
		.home-services-main-container {
			flex-wrap: wrap;
			gap: 2vw;
		
			>div {
			  width: 48%;
			}
		
			.reverse-col {
			  flex-direction: column-reverse;
			}
		  }
		  .home-services-main-container > div {
			.big-pink  {
			  width: 25vw;
			  height: 25vw;
			}
			.big-yellow{
			  width: 25vw;
			  height: 25vw;
			}
			p {
			  font-size: 16px;
			  line-height: 16px;
			}
			h6 {
			  font-size: 14px;
			  text-align: center;
		  }
		  .spacer {
			width: 5px;
		  }
		  .spacer-yellow {
			width: 5px;
		  }
		  }
	}